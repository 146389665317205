<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :cards="tendererList"
        :statuses="statuses"
        :title="title"
        filter-by-status
      >
        <template v-slot:default="{ card: tenderer, status }">
          <pro-deck-card
            :disable="tenderer.removed"
            :status="status ? status.label : ''"
            :statusColor="status ? status.color : ''"
            :title="tenderer.cmpyNameEng"
            expandable
            status-color="red"
          >
            <div class="row">
              <div class="col-auto">
                <pro-key-value-table
                  :data="tenderer"
                  :rows="tendererTableRows"
                />
              </div>
            </div>
            <div v-if="tenderStatus > 0" class="q-mt-md">
              <div class="row q-col-gutter-x-md q-col-gutter-y-sm">
                <div class="col-6">
                  <div class="row no-wrap justify-between items-end">
                    <div
                      :class="tenderer.replySlip ? 'text-green' : 'text-grey-6'"
                      class="col-auto text-h4"
                    >
                      {{ getRes("Document.TendererList.ReplySlip") }}
                    </div>
                    <div class="col-auto text-green text-h6">
                      {{
                        tenderer.replySlip
                          ? $proSmart.common.format.dateTime(tenderer.replySlip)
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row no-wrap justify-between items-end">
                    <div
                      :class="
                        tenderer.submitInterest ? 'text-green' : 'text-grey-6'
                      "
                      class="col-auto text-h4"
                    >
                      {{ getRes("Document.TendererList.SubmitInterest") }}
                    </div>
                    <div class="col-auto text-green text-h6">
                      {{
                        tenderer.submitInterest
                          ? $proSmart.common.format.dateTime(
                              tenderer.submitInterest
                            )
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <q-linear-progress
                    :value="tenderer.replySlip ? 1 : 0"
                    color="green"
                    stripe
                  />
                </div>
                <div class="col-6">
                  <q-linear-progress
                    :value="tenderer.submitInterest ? 1 : 0"
                    color="green"
                    stripe
                  />
                </div>
              </div>
            </div>
          </pro-deck-card>
        </template>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProKeyValueTable },
  data() {
    return {
      tenderStatus: 0,
      tendererList: [],
      moduleName: "",
      statuses: [
        {
          key: "InviteSupplier",
          label: this.getRes("Form.Field.InviteSupplier"),
          color: "green",
        },
        {
          key: "OpenSupplier",
          label: this.getRes("Form.Field.OpenSupplier"),
          color: "purple",
        },
        {
          key: "PreviousSupplier",
          label: this.getRes("Form.Field.PreviousSupplier"),
          color: "black",
        },
      ],
    };
  },
  computed: {
    tendererTableRows() {
      return [
        {
          label: this.getRes("Supplier.SupplierId"),
          field: "supplierId",
        },
        {
          label: this.getRes("Supplier.Status"),
          field: "supplierStatus",
        },
        {
          label: this.getRes("Supplier.EmailAddress"),
          field: "emailAddress",
        },
        {
          label: this.getRes("Supplier.CCPerson"),
          field: "ccPerson",
        },
        {
          label: this.getRes("Form.Col.SecurityCode"),
          field: "accessCode",
        },
        {
          label: this.getRes("Form.Field.SupplierSource"),
          field: "source",
        },
        {
          label: this.getRes("Form.Field.From"),
          field: "addFrom",
        },
      ];
    },
    title() {
      if (this.moduleName === "EOI" || this.moduleName === "PQ") {
        return this.getRes("Form.Section.InvitationList");
      } else {
        return this.getRes("Form.Section.TendererList");
      }
    },
  },
  created() {
    this.$proSmart.documentUi
      .getInfo(this, this.$route.params.id)
      .then((formData) => {
        this.moduleName = formData.moduleName;
      });

    this.$proSmart.documentUi
      .getTendererSupplierStatusList(this, this.$route.params.id, true)
      .then((tendererList) => {
        this.tendererList = tendererList
          .map((tenderer, index) => ({
            key: index,
            supplierId: tenderer.supplierId,
            cmpyNameEng: tenderer.name,
            emailAddress: tenderer.emailAddress.address,
            ccPerson: tenderer.ccList.map((cc) => cc.address).join("; "),
            removed: !tenderer.active,
            source:
              // tenderer.source == "ProSmartSupplier"
              //   ? this.getRes("Form.Field.ProSmartSupplier")
              //   : tenderer.supplierId == null
              //   ? this.getRes("Form.Field.LightweightSupplier")
              //   : this.getRes("Form.Field.OracleRegisteredSupplier"),
              tenderer.source === "ProSmartSupplier"
                ? this.getRes("Form.Field.ProSmartSupplier")
                : tenderer.source === "OpenRegister"
                ? this.getRes("Form.Field.OpenRegister")
                : "manual",
            accessCode: tenderer.accessCode,
            supplierStatus: tenderer.supplierStatus
              ? this.$t(`Supplier.Status.${tenderer.supplierStatus}`)
              : "",
            addFrom: tenderer.addFrom
              ? this.getRes(`Form.Field.${tenderer.addFrom}`)
              : "",
            status: tenderer.addFrom,
          }))
          .sort(function (a, b) {
            if (a.status === "Removed" && b.status === "Removed") {
              return a.cmpyNameEng.localeCompare(b.cmpyNameEng);
            } else if (a.status === "Removed") {
              return 1;
            } else if (b.status === "Removed") {
              return -1;
            }
            return a.cmpyNameEng.localeCompare(b.cmpyNameEng);
          });
      });
  },
};
</script>
